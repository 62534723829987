.main-content {
  display: flex;
  justify-content: space-between;
  margin: 16px 32px;
}

.grid {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.grid-cell {
  border: 1px solid black;
  width: 14px; 
  height: 14px;
  border-radius: 2px;
}

.center {
  border-radius: 0px;
  outline: 3px solid rgba(0, 0, 0, 0.795);
  outline-offset: 14px;
}

.grid-cell-lrg {
  border: 1px solid black;
  width: 32px; 
  height: 32px;
  border-radius: 4px;
}

.side-bar {
  border: 3px solid black;
  border-radius: 12px;
  padding: 8px;
}

.side-bar p {
  font-size: 18px;
  font-weight: 500;
}

.footer {
  padding: 12px;
  margin: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tile-selector {
  display: flex; 
  justify-content: center;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

select {
  width: 260px;
  height: 40px;

  font-weight: 600;
  font-size: 16px;

  border-radius: 8px;
}

.road {
    background-color: rgb(56, 56, 56);
}

.start {
  background-color: rgb(0, 201, 0);
}

.end {
  background-color: rgb(255, 87, 87);
}

.path {
  background-color: rgb(94, 94, 231);
}

.mild-traffic {
  background-color: rgb(255, 255, 100);
}

.moderate-traffic {
  background-color: rgb(255, 182, 45);
}

.high-traffic {
  background-color: orangered;
}

.building {
  background-color: rgb(90, 41, 41);
}

button {
  height: 40px;
  min-width: 80px;
  font-size: 18px;
  font-weight: 600;
  font-family: sans-serif;
  margin: 5px 25px;
  border-radius: 8px;
}